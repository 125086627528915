<template>
  <basic-block :title="currentTerm.termName">
    <template #search>
      <el-row :gutter="24">
        <el-col :span="12">
          <el-date-picker style="width: 100%" v-model="search.date" :size="$publicConfig.selectSize" type="daterange" range-separator="至" start-placeholder="开始日期" :default-time="['00:00:00', '23:59:59']" value-format="timestamp" @change="studentCheckRecordStatisticsStudentDetail" end-placeholder="结束日期">
          </el-date-picker>
        </el-col>
        <el-col :span="12">
          <el-select :size="$publicConfig.selectSize" v-model="search.studentId" filterable placeholder="请选择学生" @change="studentChange">
            <el-option v-for="item in allStudents" :key="item.studentId" :label="item.studentName" :value="item.studentId">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <div class="item_info">
      <div class="content">
        <div class="avater">
          <img :src="studentDetail.studentIconUrl || '/img/picture.png'" width="100%" alt="" srcset="" height="100%" />
        </div>
        <div class="info">
          <div class="name">{{ studentDetail.studentName }}</div>
          <div class="number">
            <span class="label">班级：</span>
            <span>{{ studentDetail.classInfo.gradeInfo.gradeName + studentDetail.classInfo.className }}</span>
            <span class="interval"></span>
            <span class="label">学号：</span>
            <span>{{ studentDetail.studentId }}</span>
          </div>
          <div>
            <span class="label">应考勤次数：</span>
            <span>{{ tableData.length }}次</span>
            <span class="interval"></span>
            <span class="label">实考勤次数：</span>
            <span>{{ tableData.filter(item => item.status != 4).length  }}次</span>
            <span class="interval"></span>
            <span class="label">考勤率：</span>
            <span>{{ tableData.length > 0 ? ((tableData.filter(item => item.status != 4).length) / tableData.length * 100).toFixed(2) : '0.00'}}%</span>
          </div>
        </div>
      </div>
    </div>
    <div class="content_table">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="periodId" label="考勤节次">
          <template scope="scope">
            {{setLessionPage(scope.row)}}
            <!-- 第{{ scope.row.periodId + 1 }}节课 -->
          </template>
        </el-table-column>
        <el-table-column prop="name" label="考勤时间">
          <template scope="scope">
            {{ $cftY(scope.row.time) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="考勤结果">
          <template scope="scope">
            <el-tag :style="`width: 40px;text-align: center;color:${$publicConfig.attendanceTypeColor[scope.row.status - 1]};border-color: ${$publicConfig.attendanceTypeColor[scope.row.status - 1]}`">{{ $publicConfig.attendanceType[scope.row.status - 1] }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="name" label="考勤次数"></el-table-column> -->
        <el-table-column prop="name" label="请假状态">
          <template scope="scope">
            {{ scope.row.leave ? '已请假' : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="类型">
          <template scope="scope">
            {{ scope.row.leave ? $publicConfig.leaveType[scope.row.leave.type] : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="备注">
          <template scope="scope">
            {{ scope.row.leave ? scope.row.leave.reason : '无' }}
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作">
          <template slot-scope="scope">
            <el-link type="primary" v-if="scope.row.status == 1" disabled>修改</el-link>
            <el-link type="primary" v-else @click="() => {
              form = JSON.parse(JSON.stringify(scope.row))
              dialogFormVisible = true
              }">修改</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <update-status v-if="dialogFormVisible" :visible.sync="dialogFormVisible" :form.sync="form" ref="updateStatus" :recordType="1"></update-status>
  </basic-block>
</template>

<script>
import UpdateStatus from "@/views/attendance/analysis/components/updateStatus";
import { getStudent } from "@/http/daping";
import { mapGetters } from "vuex";
import { studentCheckRecordStatisticsStudentDetail } from "@/http/analysis";
import { getSchoolTerm } from "@/http/leaveSchool";

export default {
  data() {
    return {
      form: {},
      search: {
        studentId: "",
        date: [
          new Date(this.$cftY().split(" ")[0] + " 00:00:00").getTime(),
          new Date(this.$cftY().split(" ")[0] + " 23:59:59").getTime(),
        ],
      },
      studentDetail: {
        classInfo: {
          gradeInfo: {},
        },
      },
      dialogFormVisible: false,
      detail: {},
      allStudents: [],
      tableData: [{}],
      currentTerm: {},
    };
  },
  components: {
    UpdateStatus,
  },
  computed: {
    ...mapGetters(["userInfo", "token"]),
  },
  created() {
    let params = {
      schoolId: this.userInfo.tnId,
      termId: 0,
    };
    getSchoolTerm(params).then((res) => {
      this.currentTerm = res.data;
    });
    this.detail = this.$route.query;
    this.search.studentId = this.$route.query.studentId;
    this.getStudent();
    this.studentCheckRecordStatisticsStudentDetail();
  },
  methods: {
    setLessionPage(row) {
      if (row.checkInType == 5) {
        return `第${row.periodId + 1}节课`;
      }

      switch (row.checkInType) {
        case 1:
          return "上午放学";
        case 2:
          return "下午放学";
        case 3:
          return "下午上学";
        case 4:
          return "下午放学";
        default:
          return "-";
      }

      // return row.checkInType;
    },
    studentCheckRecordStatisticsStudentDetail() {
      this.search.schoolId = this.userInfo.tnId;
      studentCheckRecordStatisticsStudentDetail(this.search).then((res) => {
        this.tableData = res.data || [];
      });
    },
    getStudent() {
      let params = {
        schoolId: this.userInfo.tnId,
        gradeCodes: [],
        classCodes: [],
        studentIds: [],
        keyword: "",

      };
      getStudent(params).then((res) => {
        console.log(res)
        this.allStudents = res.data;
        this.studentChange(this.search.studentId);
      });
    },
    studentChange(e) {
      console.log(e)
      console.log(this.allStudents,'6566565')
      this.studentDetail = this.allStudents.find((item) => item.studentId == e);
    },
  },
  watch: {
    dialogFormVisible(n) {
      if (n == false) {
        this.studentCheckRecordStatisticsStudentDetail();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item_info {
  width: 100%;
  height: 145px;
  background: #f7f8fa;
  border-radius: 2px;
  .content {
    display: flex;
    padding: 10px 47px;
    height: 100%;
    .avater {
      width: 114px;
      height: 126px;
      margin-right: 44px;
    }
    .info {
      font-size: 14px;
      color: #333333;
      span {
        display: inline-block;
      }
      .interval {
        width: 20px;
      }
      .name {
        font-size: 22px;
        font-weight: 500;
        color: #333333;
      }
      .number {
        margin: 18px 0 12px 0;
      }
      .label {
        color: #999999;
      }
    }
  }
}
.content_table {
  margin-top: $main-padding;
}
</style>